



































































import { Component, Vue, Watch } from "vue-property-decorator";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";

import Select from "@/layouts/components/Select.vue";
import Button from "@/layouts/components/Button.vue";
import { BOverlay } from "bootstrap-vue";

import { AgendaCoreMixin } from "../../layouts/mixins/agenda_core";
import { crudGet } from "@/api/_request";

async function fetchEvents(range: any, params: any = {}) {
	const { data: events } = await crudGet("app", "solutionAppointment", {
		page: 1,
		limit: 0, //no limit
		shouldGetOnlyWithSchedule: true,
		shouldFormatAsCalendarEvent: true,
		appointmentStatus: JSON.stringify(["CONFIRMED", "COMPLETED"]),
		range,
		...params, //filter params specified in "filtersMap"
	});
	return events;
}

const filtersMap = {
	qualificationsIds: "qualificationIdFilter",
	insurerPlanId: "insurerPlanIdFilter",
	corporateClientId: "corporateClientIdFilter",
	clientId: "clientIdFilter",
};

@Component({
	components: {
		FullCalendar,
		Select,
		Button,
		BOverlay,
	},
	mixins: [AgendaCoreMixin("schedules", fetchEvents, filtersMap)],
})
export default class SchedulesAgenda extends Vue {
	qualificationIdFilter = null;
	corporateClientIdFilter = null;
	insurerPlanIdFilter = null;
	clientIdFilter: string | null = null;

	isCorporateClientSelectLoading: boolean = false;
	alreadyFetchedPlans: any = {};
	insurerPlansOptions = [];

	isFromPromotersPortal = this.$route.name === "portalPromotoresAgendamentos";

	//WARN "fetchEvents" function is call right after this hook
	beforeMount() {
		if (this.isFromPromotersPortal) {
			this.clientIdFilter = this.$route.params.clientId;
			(<any>this).setFilters();
		}
	}

	async mounted() {
		if (this.isFromPromotersPortal) {
			(<any>this).hideApplyFiltersButton();
		}
	}

	async fetchInsurerPlans(corporateClientId: string) {
		let plans = [];
		if (this.alreadyFetchedPlans[corporateClientId]) {
			plans = this.alreadyFetchedPlans[corporateClientId];
		}
		if (corporateClientId) {
			this.isCorporateClientSelectLoading = true;
			const array = await crudGet("admin/insurerPlan", "", {
				s: { "corporateClient.id": { $eq: corporateClientId } },
				join: ["corporateClient||id"],
				select: ["id", "name"],
			});
			plans = array.map(({ id, name }: any) => ({ value: id, name }));
			this.alreadyFetchedPlans[corporateClientId] = plans;
			this.isCorporateClientSelectLoading = false;
		}
		this.insurerPlansOptions = plans;
	}

	@Watch("corporateClientIdFilter")
	corporateClientIdFilterChange() {
		//quando muda a seleção do cliente corporativo, limpa o plano selecionado
		this.insurerPlanIdFilter = null;
	}
}
