var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.isFromPromotersPortal)?_c('Button',{staticClass:"mb-2",attrs:{"icon":'arrow-back.svg',"variant":'tertiary',"iconStyle":'h-4 w-4 mr-1'},on:{"click":function () { return _vm.$router.back(); }}},[_vm._v("Voltar")]):_vm._e(),(!_vm.isFromPromotersPortal)?_c('div',{staticClass:"grid grid-cols-3 gap-4 items-end mb-2"},[_c('Select',{staticClass:"flex-grow",attrs:{"selectStyle":'w-full',"axis":'col',"labelWidth":'100%',"shouldDisplayAnyway":true,"selectLabel":'Qualificações',"fetchOptions":{
				endpoint: 'admin/qualification',
				nameKey: 'name',
				valueKey: 'id',
				allOption: 'Todas',
				params: { s: { icon: { $in: ['telemedicine', 'presential'] } }, select: ['id', 'name'] },
			}},model:{value:(_vm.qualificationIdFilter),callback:function ($$v) {_vm.qualificationIdFilter=$$v},expression:"qualificationIdFilter"}}),_c('Select',{staticClass:"flex-grow",attrs:{"selectStyle":'w-full',"axis":'col',"labelWidth":'100%',"shouldDisplayAnyway":true,"onChange":_vm.fetchInsurerPlans,"selectLabel":'Clientes corporativos',"isLoading":_vm.isCorporateClientSelectLoading,"fetchOptions":{
				endpoint: 'admin/corporateClient',
				nameKey: 'name',
				valueKey: 'id',
				allOption: 'Todos',
				params: { select: ['id', 'name'] },
			}},model:{value:(_vm.corporateClientIdFilter),callback:function ($$v) {_vm.corporateClientIdFilter=$$v},expression:"corporateClientIdFilter"}}),_c('Select',{attrs:{"axis":'col',"labelWidth":'100%',"selectStyle":'w-full',"shouldDisplayAnyway":true,"selectLabel":'Planos relacionados ao cliente',"options":_vm.insurerPlansOptions,"onChange":function () {}},model:{value:(_vm.insurerPlanIdFilter),callback:function ($$v) {_vm.insurerPlanIdFilter=$$v},expression:"insurerPlanIdFilter"}})],1):_vm._e(),_c('div',{staticClass:"h-full relative py-1"},[_c('div',{staticClass:"full-calendar-loading",attrs:{"id":"schedules-calendar-loading"}},[_c('BOverlay',{attrs:{"show":true,"z-index":"9001","no-wrap":true}})],1),_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }